import React from 'react'

function Footer() {
  return (
    <div className='footer flex justify-center items-start bg-[#990147] py-5 text-white text-left' style={{minHeight: '400px', width: '100%'}}>
      <section className="flex flex-col text-left mx-3" style={{width: '40%'}}>
        <div className='text-left w-full mb-3 py-3' style={{borderBottom: '1px solid white'}}>
          <p>About Us</p>
        </div>
        <div>
          <p>Ghana Students’ Most Beautiful is a TV Reality Program just like you for Miss Ghana, Ghana’s Most Beautiful, Miss Malaika etc. it is the highest Beauty Pageantry granted to all Female students across all Institutions in Ghana.

The Ghana Students’ Most Beautiful started in 2021 and the next one will be the 3rd Edition.</p>
        </div>
      </section>
      <section className="flex flex-col text-left mx-3" style={{width: '40%'}}>
        <div className='text-left w-full mb-3 py-3' style={{borderBottom: '1px solid white'}}>
          <p>Contact Us</p>
        </div>
        <div className="flex flex-col w-full items-start text-left justify-between h-40">
          <p className='text-left'>Accra, Ghana</p>
 
          <p className='text-left'>+233204933598/+233548503799</p>
          <p className='text-left'>ghanastudentsmostbeautiful@gmail.com</p>
        </div>
      </section>
    </div>
  )
}

export default Footer